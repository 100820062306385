.chart {
  margin: 20px;
  padding: 20px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
}

.chartTitle {
  margin-bottom: 20px;
}
.label_EU{
    color:black ;
}
.label_Team{
    color:#f2c971 ;
}
.label_power{
    color: #3256ed;
}
.label_endurance{
    color: #db4f9a;
}
.label_motor{
    color: #ffc440;
}
.label_oxygen{
    color: #e43623;
}
.label_injury{
    color: #bb3ac2;
}
