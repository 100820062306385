* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body{
    width: 100%;
    height: 100%;
}

.geneticstatswrapper{
    width: 100%;
}

.geneticstatscontainer{
    display: flex;
    background-color: white;
}

.geneticstatssubcontainer{
    flex: 5;
    margin-top: 20px;
 }
