* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body{
    height: 100%;
    background-color: white;
}

.playerlistpagewrapper {
    width: 100%;  
     
}

.playerlistpagecontainer {
    display: flex;
    height: auto;
    background-color: white;
}

/* Add a background color and some padding around the form */
.playerlistpagesubcontainer {
    flex: 5;
    min-width: 1150px;
    margin-top: 20px;
}