* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body{
    width: 100%;
    height: 100%;
}

.comparisonwrapper{
    width: 100%;
}

.comparisoncontainer{
    display: flex;
    background-color: white;
}

.comparisonsubcontainer{
    padding-left: 20px;
    flex: 5;
    margin-top: 20px;
    min-width: 1150px;
}

.comparisonsubcontainer-column {
    display: flex;
    
}

.column {
    flex: 50%;
    margin-top: 20px;
  }

.select-button{
    margin-top: 10px;
    margin-bottom: 10px;
}

.comparisonsubsubcontainer{
    flex: 1;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    padding: 20px;
    margin-right: 20px;
}