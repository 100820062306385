.player {
  flex: 5;
  padding: 10px;
}

.playerTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.playerAddButton {
  width: 80px;
  border: none;
  padding: 5px;
  background-color: teal;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-size: 16px;
}

.playerContainer {
  display: flex;
  margin-top: 20px;
}

.playerShow {
  flex: 1;
  padding: 20px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
}

.playerUpdate {
  flex: 2;
  padding: 20px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  margin-left: 20px;

}

.playerShowTop {
  display: flex;
  align-items: center;
}

.playerShowImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.playerShowTopTitle {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.playerShowUsername {
  font-weight: 600;
}

.playerShowUserTitle {
  font-weight: 300;
}

.playerShowBottom{
    margin-top: 20px;
}

.playerShowTitle {
  font-size: 14px;
  font-weight: 600;
  color: rgb(175, 170, 170);
}

.playerShowInfo{
    display: flex;
    align-items: center;
    margin: 20px 0px;
    color: #444;
}

.playerShowIcon{
    font-size: 16px !important;
}

.playerShowInfoTitle{
    margin-left: 10px;
}

.playerUpdateTitle{
    font-size: 24px;
    font-weight: 600;
}

.playerUpdateForm{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.playerUpdateItem{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.playerUpdateItem>label{
    margin-bottom: 5px;
    font-size: 14px;
}

.playerUpdateInput{
    border: none;
    width: 250px;
    height: 30px;
    border-bottom: 1px solid gray;
}

.playerUpdateRight{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.playerUpdateUpload{
    display: flex;
    align-items: center;
}

.playerUpdateImg{
    width: 100px;
    height: 100px;
    border-radius: 10px;
    object-fit: cover;
    margin-right: 20px;
}

.playerUpdateIcon{
    cursor: pointer;
}

.playerUpdateButton{
    border-radius: 5px;
    border: none;
    padding: 5px;
    cursor: pointer; 
    background-color: #a4162b;
    color: white;
    font-weight: 600;
}

.playerDeleteContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}

.playerDeleteButton{
  cursor: pointer; 
  color: red;
}

.playerDeleteTitle{
  color: red !important;
  font-weight: 600;
}