.genotypeListUser {
    display: flex;
    align-items: center;
}

.genotypeListImg {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}

.genotypeListNavigateProfile {
    cursor: pointer;
    color: #3bb077;
    margin-right: 10px;
    vertical-align: middle;
}

.genotypeListNavigateReport {
    cursor: pointer;
    color: blue;
    margin-right: 10px;
}
