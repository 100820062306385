.playerListUser {
  display: flex;
  align-items: center;
}

.playerListImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.playerListEdit {
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: #3bb077;
  color: white;
  cursor: pointer;
  margin-right: 10px;
  font-size: 10px;
}

.playerListNavigateProfile {
  cursor: pointer;
  color:#a4162b;
  margin-right: 10px;
  vertical-align: middle;
}

/* .playerListDelete {
  color: red;
  cursor: pointer;
  margin-right: 10px;
} */