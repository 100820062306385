.featured {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
}

.featuredItem {
  flex: 1;
  height: fit-content;
  margin: 0px 20px;
  padding: 10px 30px 10px 30px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  background: #a4162b;
  color: white;
}

.featuredTitle{
    font-size: 15px;
}

.featuredMoneyContainer{
    margin: 5px 0px;
    display: flex;
    align-items: center;
}

.featuredMoney{
    font-size: 25px;
    font-weight: 600;
}

.featuredMoneyRate{
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.featuredIcon{
    font-size: 14px;
    margin-left: 5px;
    color: green;
}

.featuredSub{
    font-size: 12px;
    color: white;;
}