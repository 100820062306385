* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body{
    height: 100%;
}
.geneparamsdetailwrapper{
    width: 100%;
}

.geneparamsdetailcontainer{
    display: flex;
    background-color: white;
}

.geneparamsdetailsubcontainer{
    flex: 5;
    margin-top: 20px;
    min-width: 1150px;

 }


.geneparamsdetailwidgetLg {
    flex: 2;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    padding: 20px;

  }

.geneparamsdetailwidgetLgTitle {
    font-size: 22px;
    font-weight: 600;
  }

p{
    font-weight: 400;
}
 .myDiv{
	display:none;
  margin-left: 5px;

}  