* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body{
    height: 100%;
    background-color: white;
}

.geneticscoreswrapper{
    width: 100%;  
}

.geneticscorescontainer{
    display: flex;
    height: auto;
    background-color: white;
}

.geneticscoressubcontainer{
   flex: 5;
   min-width: 1150px;
}
