.grid-container{
    display: grid;
    grid-gap: 10px;
    
}

.grid-item1 { grid-area: 1 / 1 / 2 / 2; }
.grid-item2 { grid-area: 1 / 2 / 2 / 3; }
.grid-item3 { grid-area: 2 / 1 / 3 / 2; }
.grid-item4 { grid-area: 2 / 2 / 3 / 3; }

.genephysicalbarcharttitle{
    margin-top: 10px;
}
/* .grid-item5 { grid-area: 1 / 5 / 2 / 6; }
.grid-item6 { grid-area: 2 / 1 / 3 / 2; } */

/* .GFgrid-item7 { grid-area: 2 / 2 / 3 / 3; }
.GFgrid-item8 { grid-area: 2 / 3 / 3 / 4; }
.GFgrid-item9 { grid-area: 2 / 4 / 3 / 5; }
.GFgrid-item10 { grid-area: 2 / 5 / 3 / 6; }

.GFgrid-item11 { grid-area: 3 / 1 / 4 / 2; }
.GFgrid-item12 { grid-area: 3 / 2 / 4 / 3; }
.GFgrid-item13 { grid-area: 3 / 3 / 4 / 4; }
.GFgrid-item14 { grid-area: 3 / 4 / 4 / 5; }
.GFgrid-item15 { grid-area: 3 / 5 / 4 / 6; } 

.GFgrid-item16 { grid-area: 4 / 1 / 5 / 2; }
.GFgrid-item17 { grid-area: 4 / 2 / 5 / 3; }
.GFgrid-item18 { grid-area: 4 / 3 / 5 / 4; }
.GFgrid-item19 { grid-area: 4 / 4 / 5 / 5; }
.GFgrid-item20 { grid-area: 4 / 5 / 5 / 6; } 

.GFgrid-item21 { grid-area: 5 / 1 / 6 / 2; }
.GFgrid-item22 { grid-area: 5 / 2 / 6 / 3; }
.GFgrid-item23 { grid-area: 5 / 3 / 6 / 4; }
.GFgrid-item24 { grid-area: 5 / 4 / 6 / 5; }
.GFgrid-item25 { grid-area: 5 / 5 / 6 / 6; }  */