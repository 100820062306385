* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body{
    width: 100%;
    height: 100%;
}

.newplayerpagewrapper{
    width: 100%;
}

.newplayerpagecontainer{
    display: flex;
    background-color: white;
}

.newplayerpagesubcontainer{
    flex: 5;
    margin-top: 20px;
    min-width: 1150px;
    padding-left: 10px;
 }