* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.playerprofilepagewrapper {
    width: 100%;
}

.playerprofilepagecontainer {
    display: flex;
    background-color: white;
}

/* Add a background color and some padding around the form */
.playerprofilepagesubcontainer {
    flex: 10;
    border-radius: 5px;
    padding: 30px;
    
  }