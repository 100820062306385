.sidebar {
  flex: 1;
  height: calc(100vh);
  background-color: rgb(251, 251, 255);
  position: sticky;
  top: 50px;
}

.sidebarWrapper {
  padding: 25px;
  color: #555;
}

.sidebarMenu {
  margin-bottom: 10px;
}

.sidebarTitle {
  font-size: 13px;
  color: #a4162b;
}

.sidebarList {
  list-style: none;
  padding: 5px;
}

.sidebarListItem {
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
  font-size: 12px;
}

.sidebarListItemActive {
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
  font-size: 12px;
  background-color: #a4162b;
  color: white;
}

.sidebarListItemPassive {
  padding: 5px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  font-size: 12px;
}

.sidebarListItem:hover{
  background-color: rgb(240, 240, 255) !important;

}

.sidebarIcon{
    width: 10%;
    margin-right: 5px;
    font-size: 20px !important;
}

/* Dropdown Button */
.sidebar-dropbtn {
  background-color: #a4162b;
  color: white;
  border-radius: 8px;
  padding: 8px;
  font-size: 14px;
  border: #a4162b;
  cursor: pointer;
  width: 160px;
}


/* The container <div> - needed to position the dropdown content */
.sidebar-dropdown {
  position: relative;
  align-items: center;
  display: inline-block;
}

/* Dropdown button on hover & focus */
.sidebar-dropbtn:hover, .dropbtn:focus {
  background-color: rgb(240, 240, 255);
  color: #555;
  border: none;
}

/* Dropdown Content (Hidden by Default) */
.sidebar-dropdown-content {
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.sidebar-dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.sidebar-dropdown-content a:hover {background-color: #ddd;}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.sidebar-show {display:block;}
