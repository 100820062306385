* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.registerdatawrapper {
    width: 100%;
    height: 100%;
}

.registerdatacontainer {
    display: flex;
    background-color: white;
}

/* Add a background color and some padding around the form */
.registerdatasubcontainer {
    flex: 5;
    margin-top: 20px;
    border-radius: 5px;
    min-width: 1150px;
    padding: 10px;
    
  }