

.topbar {
  width: 100%;
  height: 50px;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 999;
}

.topbarWrapper {
  height: 100%;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.topLeft{
  display: flex;
  align-items: center;
}

.logo {
  margin-left: 10px;
  font-weight: bold;
  font-size: 30px;
  color: #a4162b;
  cursor: pointer;
}

.teamlogo{
  width: 40px;
  height: 40px;
  
}

.topRight{
    display: flex;
    align-items: center;
}

.topbarIconContainer {
  position: relative;
  cursor: pointer;
  margin-right: 10px;
  color: #555;
}

.topIconBadge{
    width: 15px;
    height: 15px;
    position: absolute;
    top: -5px;
    right: 0px;
    background-color: red;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
}

.topAvatar{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
}

.dropbtn {
  background-color: white;
  color: #555;
  padding: 5px;
  border: none;
  cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
  
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #f1f1f1}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #17e0bb;
}

.topbarIconContainer:hover .dropbtn{
  background-color: #17e0bb;
}