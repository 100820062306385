.newPlayer {
  flex: 4;
}

.newPlayerForm {
  display: flex;
  flex-wrap: wrap;
}

.newPlayerItem {
  width: 400px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-right: 20px;
}

.newPlayerItem > label {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
  color: rgb(151, 150, 150);
}

.newPlayerItem > input {
  height: 20px;
  padding: 10px;
  border: 1px solid gray;
  border-radius: 5px;
}

.newPlayerGender > input {
  margin-top: 15px;
}

.newPlayerGender>label{
    margin: 10px;
    font-size: 18px;
    color: #555;
}

.newPlayerSelect{
    height: 40px;
    border-radius: 5px;
}

.newPlayerButton{
    width: 200px;
    border: none;
    background-color: darkblue;
    color: white;
    padding: 7px 10px;
    font-weight: 600;
    border-radius: 10px;
    margin-top: 30px;
    cursor: pointer;
}