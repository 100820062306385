.product {
  flex: 4;
  padding: 10px;
}

.productTop {
  display: flex;
}

.productTopLeft{
  flex: 3;
}
.productTopRight {
  flex: 2;
}

.productTopRight, .productTopLeft {
  padding: 20px;
  height: auto;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
}

.radarImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

.productInfoImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}

.productInfoTop {
  display: flex;
  align-items: center;
}

.productName {
  font-weight: 600;
}

.productInfoBottom {
  margin-top: 10px;
}

.productInfoItem {
  width: 200px;
  display: flex;
  justify-content: space-between;
}

.productInfoValue {
  font-weight: 300;
}

.productBottom {
  padding: 20px;
  margin: 20px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
}

.productForm {
  display: flex;
  justify-content: space-between;
}

.productFormLeft {
  display: flex;
  flex-direction: column;
}

.productFormLeft > label {
  margin-bottom: 10px;
  color: gray;
}

.productFormLeft > input {
  margin-bottom: 10px;
  border: none;
  padding: 5px;
  border-bottom: 1px solid gray;
}

.productFormLeft >select{
  margin-bottom: 10px;
}

.productFormRight{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

h5{
  margin-bottom: 10px;
}


.geneparamsdetailwidgetLg {
  flex: 2;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  padding: 10px;
}

.geneparamsdetailwidgetLgTitle {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}

.buttondetailedreport {
  position: relative;
  left: 0;
  bottom: 0;
}

.playerInfoReportButton{
 cursor: pointer
}

.playerInfoReportButton:hover{
  cursor: pointer;
  opacity: 0.7; 
 }

 .myDiv{
	display:none;
  margin-left: 5px;

}  

