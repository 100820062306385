.physicalstatswrapper {
    display: 4;
}

.physicalstatssubcontainer {
    display: flex;
    margin: 10px;
}

.physicalstatsgrid-item {
    flex: 2;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    padding: 10px;
    padding-left: 23%;
    padding-right: auto;
    font-weight: 600;
}