* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body{
    height: 100%;
    background-color: white;
}

.genotypelistwrapper{
    width: auto;
      
}

.genotypelistcontainer{
    display: flex;
    height: auto;
    background-color: white;
}

.genotypelistsubcontainer{
    flex: 5;
    margin-top: 20px;
    min-width: 1150px;
}