/* Style inputs with type="text", select elements and textareas */
input[type=date], select
{
    width: 100%;
    /* Full width */
    padding: 12px;
    /* Some padding */
    border: 1px solid #ccc;
    /* Gray border */
    border-radius: 4px;
    /* Rounded borders */
    box-sizing: border-box;
    /* Make sure that padding and width stays in place */
    margin-top: 6px;
    /* Add a top margin */
    margin-bottom: 16px;
    /* Bottom margin */

}

/* Style the submit button with a specific background color etc */
input[type=submit], button[type=submit] {
    background-color: darkblue;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

input[type=submit]:hover {
    opacity: 0.5;
}

.table {
    font-family: Poppins, Helvetica, sans-serif;
    width: 100%;
}

.table thead {
    position: sticky;
    top: 0;
}

.table thead th {
    border: 1px solid #e4eff8;
    background: white;
    cursor: pointer;
}

.table thead th.header-label {
    background:  #a4162b ;
    color: white;
    border: 1px solid white;
}

.table th,
.table td {
    padding: 0.2rem 0.5rem;
    text-align: center;
    width: 150px;
    vertical-align: middle;
    
}

.table td {
    border: 1px solid #e4eff8;
}

.table .tr-inputs th {
    position: relative;
    padding: 0;
    padding-bottom: 1.2rem;
    margin: 0;

}

.table .tr-inputs div {
    position: absolute;
    display: inline-block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

tbody::before
{
  content: '';
  display: block;
  height: 10px;

}

