.scoreListUser {
  display: flex;
  align-items: center;
}

.scoreListImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

/* .userListEdit {
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: #3bb077;
  color: white;
  cursor: pointer;
  margin-right: 20px;
} */

.scoreListNavigateReport {
  cursor: pointer;
  color: #a4162b;
  margin-right: 10px;
}

.scoreListNavigateProfile {
  cursor: pointer;
  color:#3bb077;
  margin-right: 10px;
  vertical-align: middle;
}
