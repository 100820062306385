* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body{
    height: 100%;
}
.homewrapper{
    width: 100%;
}

.homecontainer{
    display: flex;
    background-color: white;
}

.homesubcontainer{
    flex: 5;
    margin-top: 20px;
    min-width: 1150px;
}

