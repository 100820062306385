* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.sendmessageswrapper {
    width: 100%;
}

.sendmessagescontainer {
    display: flex;
    background-color: white;
}

/* Add a background color and some padding around the form */
.sendmessagessubcontainer {
    flex: 5;
    border-radius: 5px;
    padding: 30px;
    min-width: 1150px;
  }

input[type=text] {
    width: 100%; /* Full width */
    height: 20px;
    padding: 12px; /* Some padding */ 
    border: 2px solid darkblue; /* Gray border */
    border-radius: 4px; /* Rounded borders */
    box-sizing: border-box; /* Make sure that padding and width stays in place */
    margin-top: 6px; /* Add a top margin */
    margin-bottom: 16px; /* Bottom margin */
    align-items: left;
    resize: vertical /* Allow the user to vertically resize the textarea (not horizontally) */
}

/* Style inputs with type="text", select elements and textareas */
textarea {
    width: 100%; /* Full width */
    height: 200px;
    padding: 12px; /* Some padding */ 
    border: 2px solid darkblue; /* Gray border */
    border-radius: 4px; /* Rounded borders */
    box-sizing: border-box; /* Make sure that padding and width stays in place */
    margin-top: 6px; /* Add a top margin */
    margin-bottom: 16px; /* Bottom margin */
    resize: vertical /* Allow the user to vertically resize the textarea (not horizontally) */
  }
  
  /* Style the submit button with a specific background color etc */
  button[type=submit] {
    background-color: darkblue;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  /* When moving the mouse over the submit button, add a darker green color */
  button[type=submit]:hover {
    background-color: #45a049;
  }
  
